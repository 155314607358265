// const API_URL = 'https://kb-strapi.onrender.com/api';
// const SERVER_URL = 'https://kb-strapi.onrender.com';


const API_URL = 'https://cms.kyivbiennial.org/api';
const SERVER_URL = 'https://cms.kyivbiennial.org.';


// const API_URL = 'http://localhost:1337/api';
// const SERVER_URL = 'http://localhost:1337';


export  {API_URL, SERVER_URL};